

<template>
    <div class="page">
        <div class="title">
            <a href="weixin://dl/business/?appid=wxbdab6218c3e2b62c&path=pages/guanjia/index">{{
                product.BHZSM }}</a>

        </div>




        <div class="t-cell-group t-cell-group--default t-cell-group--bordered">
            <div class="t-cell t-cell--middle">
                <div class="t-cell__left">产品编号</div>
                <div class="t-cell__title">&nbsp;</div>
                <div class="t-cell__right">
                    {{ product.CHANPBH }}
                </div>
            </div>
            <div class="t-cell t-cell--middle">
                <div class="t-cell__left">主石重</div>
                <div class="t-cell__title">&nbsp;</div>
                <div class="t-cell__right">
                    {{ product.ZHUSZ }}
                </div>
            </div>




            <div class="t-cell t-cell--middle">
                <div class="t-cell__left">净度</div>
                <div class="t-cell__title">&nbsp;</div>
                <div class="t-cell__right">
                    {{ product.ZHUSJB }}
                </div>
            </div>

            <div class="t-cell t-cell--middle">
                <div class="t-cell__left">颜色</div>
                <div class="t-cell__title">&nbsp;</div>
                <div class="t-cell__right">
                    {{ product.ZHUSJD }}
                </div>
            </div>


            <div class="t-cell t-cell--middle">
                <div class="t-cell__left">粒数</div>
                <div class="t-cell__title">&nbsp;</div>
                <div class="t-cell__right">
                    {{ product.ZHUSLS }}
                </div>
            </div>


            <div class="t-cell t-cell--middle">
                <div class="t-cell__left">金重</div>
                <div class="t-cell__title">&nbsp;</div>
                <div class="t-cell__right">
                    {{ product.JINZ }}
                </div>
            </div>

            <div class="t-cell t-cell--middle">
                <div class="t-cell__left">件重</div>
                <div class="t-cell__title">&nbsp;</div>
                <div class="t-cell__right">
                    {{ product.JIANZ }}
                </div>
            </div>

            <div class="t-cell t-cell--middle">
                <div class="t-cell__left">证书号</div>
                <div class="t-cell__title">&nbsp;</div>
                <div class="t-cell__right">
                    {{ product.ZHENGSH }}
                </div>
            </div>
        </div>
        <div class="t-image t-image--square image-container"><!---->
            <picture><!----><img class="t-image__img" :src="product.PICTURE_LJ" alt=""
                    style="object-fit: fill; object-position: center center;"></picture>
        </div>


        <div class="t-footer">

            <div class="t-image t-image--square t-footer__icon"><!---->

            </div>
            <div class="t-footer__text">Copyright © CC.All Rights Reserved.</div>

        </div>
    </div>
</template>

<script>



import { getCurrentInstance, defineComponent, onMounted, reactive } from 'vue';


import { useRoute } from 'vue-router'
export default defineComponent({
    components: {

    },


    setup() {
        const { proxy } = getCurrentInstance();
        const axios = proxy.$axios;

        const router = useRoute();

        const product = reactive({


            "BHZSM": "",
            "CPMD": "",
            "CYHMD": "",
            "GGY": "",
            "GKTYD": "",
            "JIANZ": "",
            "JINYS": "",
            "JINZ": "",
            "PICTURE_LJ": "",
            "SHOUC": "",
            "ZHENGSH": "",
            "ZHENGSLX": "",
            "ZHUSJB": "",
            "ZHUSJD": "",
            "ZHUSLS": "",
            "ZHUSYS": "",
            "ZHUSZ": "",
            "ZSZL": ""
        });


        const loadProduct = async () => {

            let cp = router.params.id;


            let url = `/ajax/getChanpXX.ashx?chanpbh=${cp}&pinp=A`;
            let result = await axios.get(url)
                .then(res => {
                    console.log(res, 32)
                    return res.data;
                });




            if (result.status == "0") {
                alert(result.msg);
                return;
            }

            console.log(result.data[0]);


            for (const key in result.data[0]) {
                if (result.data[0][key])
                    product[key] = result.data[0][key];
            }




            product.PICTURE_LJ = 'https://wapp.cckmwx.com/pic/kuansh/CCPic/' + product.PICTURE_LJ;



        }







        onMounted(async () => {

            loadProduct();


        })

        return {
            product

        };
    },
});

</script>
<style  scoped>
.title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    padding: 24px 16px 20px;
    color: #000000e6;

}</style>
   
 
